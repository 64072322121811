import React from 'react'
import { Text, Heading, Container, Stack } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import BaseLayout from '../layout/base'
import SEO from '../components/SEO/PageSEO'

const Page = ({ location, data }) => (
  <BaseLayout>
    <SEO
      title="404 Página no encontrada"
      titleSeo="404 Página no encontrada"
      description="Esta página no existe en nuestro sitio."
      image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
      // lang="es"
      datePublished="2021-05-11T12:29:33-03:00"
      dateModified="2021-05-11T12:29:33-03:00"
      // breadcrumbs={[{ name: 'Vegetales', url: '/vegetales/' }]}
      permalink={location.pathname}
      // breadcrumbList={[]}
      noindex
    />
    <Container maxW="5xl">
      <Stack
        textAlign="center"
        align="center"
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
          lineHeight="110%"
          as="span"
        >
          <Text as="span" color="orange.400">
            404
          </Text>{' '}
          Página no encontrada
        </Heading>
        <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
          No se ha encontrado la página solicitada
        </Heading>
      </Stack>
    </Container>
  </BaseLayout>
)

export default Page

export const pageQuery = graphql`
  query {
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
